import React, {useReducer} from 'react'
const GlobalStateContext = React.createContext()
const GlobalDispatchContext = React.createContext()

const initialState = {
    date:''
}

function reducer (state, action) {
    switch (action.type) {
        case 'ON_SELECT_DATE': {
            return {
                ...state,
                date: action.date
            }
        }    
        default:
            throw new Error('BAD ACTUION')
    }
}

const GlobalContextProvider = ({children}) => {
const [state, dispatch] = useReducer(reducer, initialState)
return(
<GlobalStateContext.Provider value={state}>
    <GlobalDispatchContext.Provider value={dispatch}>
        {children}
    </GlobalDispatchContext.Provider>

</GlobalStateContext.Provider>
) 
}

  

export  {GlobalContextProvider, GlobalStateContext, GlobalDispatchContext}