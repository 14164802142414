// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-comp-t-and-c-js": () => import("./../../../src/templates/comp-t-and-c.js" /* webpackChunkName: "component---src-templates-comp-t-and-c-js" */),
  "component---src-templates-competition-js": () => import("./../../../src/templates/competition.js" /* webpackChunkName: "component---src-templates-competition-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-deliveroo-js": () => import("./../../../src/templates/deliveroo.js" /* webpackChunkName: "component---src-templates-deliveroo-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-restaurant-js": () => import("./../../../src/templates/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-js" */)
}

